import React, {
    type ComponentProps,
    useState,
    type MouseEvent,
    type RefObject,
    memo,
    forwardRef
} from 'react'
import {
    CoreTypography,
    LeafConfirmationDialog,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'
import {
    Box,
    Breakpoint,
    Button,
    type ButtonProps,
    Dialog,
    type DialogProps,
    IconButton
} from '@mui/material'
import { useIntl, defineMessages, FormattedMessage } from 'react-intl'
import { ModalButtonVariant } from '../../../types'
import { IconName } from '@fortawesome/fontawesome-common-types'

const messages = defineMessages({
    closeModalLabel: {
        defaultMessage: 'Close modal',
        description: 'Label for button to close modal'
    }
})

export { ModalButtonVariant }

export interface ModalProps {
    closeModal?: () => void
    trigger?: any
}

type LeafConfirmationDialogProps = Omit<
    ComponentProps<typeof LeafConfirmationDialog>,
    'open' | 'onClose' | 'fullWidth' | 'maxWidth' | 'fullScreen'
>

type DefaultModalProps = Omit<DialogProps, 'onClose' | 'open'>

type ModalButtonModalVariantProps =
    | {
          modalVariant?: 'confirmation'
          modalProps: LeafConfirmationDialogProps
      }
    | {
          modalVariant?: 'default'
          modalProps?: DefaultModalProps
      }

export type ModalButtonProps = ButtonProps & {
    modalButtonVariant: ModalButtonVariant
    buttonLabel?: string
    ModalComponent?: React.FC<ModalProps>
    modalSize?: Breakpoint
    modalFullScreen?: boolean
    iconName?: IconName
    buttonText?: string
    trigger?: any
} & ModalButtonModalVariantProps

const _ModalButton = (
    {
        modalButtonVariant,
        buttonLabel,
        buttonText,
        ModalComponent,
        modalSize,
        modalFullScreen,
        modalVariant = 'default',
        modalProps,
        iconName,
        trigger,
        size = 'large',
        color = 'primary',
        variant = 'text',
        ...buttonProps
    }: ModalButtonProps,
    ref: RefObject<HTMLButtonElement>
) => {
    const intl = useIntl()
    const [modalOpen, setModalOpen] = useState(false)

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setModalOpen(true)
    }

    const handleClose = () => {
        setModalOpen(false)
    }

    return (
        <>
            {modalButtonVariant === ModalButtonVariant.Default && (
                <Box>
                    <Button
                        ref={ref}
                        size={size}
                        variant={variant}
                        color={color}
                        {...buttonProps}
                        onClick={handleClick}
                        aria-label={buttonLabel}
                        startIcon={
                            iconName && (
                                <LeafIcon icon={iconName} fontSize={'small'} />
                            )
                        }
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {buttonText}
                        </CoreTypography>
                    </Button>
                </Box>
            )}
            {modalButtonVariant === ModalButtonVariant.Edit && (
                <Box>
                    <Button
                        ref={ref}
                        size={size}
                        variant={variant}
                        color={color}
                        {...buttonProps}
                        onClick={handleClick}
                        aria-label={buttonLabel}
                        startIcon={<LeafIcon icon={'pen'} fontSize={'small'} />}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {buttonText ? (
                                buttonText
                            ) : (
                                <FormattedMessage
                                    description="Label for edit button"
                                    defaultMessage="Edit"
                                />
                            )}
                        </CoreTypography>
                    </Button>
                </Box>
            )}
            {modalButtonVariant === ModalButtonVariant.Add && (
                <Box>
                    <Button
                        ref={ref}
                        size={size}
                        variant={variant}
                        color={color}
                        {...buttonProps}
                        startIcon={
                            <LeafIcon icon={'plus'} fontSize={'small'} />
                        }
                        onClick={handleClick}
                        aria-label={buttonLabel}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {buttonText ? (
                                buttonText
                            ) : (
                                <FormattedMessage
                                    description="Label for add button"
                                    defaultMessage="Add"
                                />
                            )}
                        </CoreTypography>
                    </Button>
                </Box>
            )}
            {modalButtonVariant === ModalButtonVariant.Settings && (
                <Box>
                    <Button
                        ref={ref}
                        size={size}
                        variant={variant}
                        color={color}
                        {...buttonProps}
                        startIcon={
                            <LeafIcon icon={'gear'} fontSize={'small'} />
                        }
                        onClick={handleClick}
                        aria-label={buttonLabel}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for Settings button"
                                defaultMessage="Settings"
                            />
                        </CoreTypography>
                    </Button>
                </Box>
            )}
            {modalButtonVariant === ModalButtonVariant.Icon && (
                <Box>
                    <IconButton
                        ref={ref}
                        {...buttonProps}
                        aria-label={buttonLabel}
                        color={color}
                        onClick={handleClick}
                    >
                        <LeafIcon icon={iconName} fontSize="small" />
                    </IconButton>
                </Box>
            )}
            {modalVariant === 'confirmation' && (
                <LeafConfirmationDialog
                    {...(modalProps as LeafConfirmationDialogProps)}
                    open={modalOpen}
                    fullWidth={true}
                    maxWidth={modalSize || 'xs'}
                    fullScreen={modalFullScreen}
                    onClose={handleClose}
                    primaryButtonOnClick={() => {
                        const dialogProps =
                            modalProps as LeafConfirmationDialogProps
                        dialogProps.primaryButtonOnClick?.()
                        handleClose()
                    }}
                    secondaryButtonOnClick={() => {
                        const dialogProps =
                            modalProps as LeafConfirmationDialogProps
                        dialogProps.secondaryButtonOnClick?.()
                        handleClose()
                    }}
                />
            )}
            {modalVariant === 'default' && (
                <Dialog
                    {...(modalProps as DefaultModalProps)}
                    onClose={handleClose}
                    open={modalOpen}
                    fullWidth={true}
                    maxWidth={modalSize || 'xs'}
                    fullScreen={modalFullScreen}
                    sx={{ '.MuiDialogContent-root': { pt: '1rem !important' } }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            m: 2,
                            mb: 0,
                            justifyContent: 'end'
                        }}
                    >
                        <IconButton
                            color={color}
                            aria-label={intl.formatMessage(
                                messages.closeModalLabel
                            )}
                            onClick={handleClose}
                        >
                            <LeafIcon icon={'xmark'} />
                        </IconButton>
                    </Box>
                    {ModalComponent ? (
                        <ModalComponent
                            closeModal={handleClose}
                            trigger={trigger}
                        />
                    ) : null}
                </Dialog>
            )}
        </>
    )
}

export const ModalButton = memo(
    forwardRef<HTMLButtonElement, ModalButtonProps>(_ModalButton)
)
