// based on https://github.com/thriveglobal/thrive-calls-graphapi/blob/master/src/main/kotlin/com/thriveglobal/calls/graphapi/graphql/schema/model/GenesysRegion.kt
export enum GenesysRegion {
    ApNortheast_1 = 'AP_NORTHEAST_1',
    ApNortheast_2 = 'AP_NORTHEAST_2',
    ApSoutheast_2 = 'AP_SOUTHEAST_2',
    ApSouth_1 = 'AP_SOUTH_1',
    CaCentral_1 = 'CA_CENTRAL_1',
    EuCentral_1 = 'EU_CENTRAL_1',
    EuWest_1 = 'EU_WEST_1',
    EuWest_2 = 'EU_WEST_2',
    UsEast_1 = 'US_EAST_1',
    UsEast_2 = 'US_EAST_2',
    UsWest_2 = 'US_WEST_2',
    Wiremock = 'WIREMOCK'
}

export const genesysApiHostByRegion: Record<GenesysRegion, string> = {
    [GenesysRegion.ApNortheast_1]: 'https://api.mypurecloud.jp',
    [GenesysRegion.ApNortheast_2]: 'https://api.apne2.pure.cloud',
    [GenesysRegion.ApSoutheast_2]: 'https://api.mypurecloud.com.au',
    [GenesysRegion.ApSouth_1]: 'https://api.aps1.pure.cloud',
    [GenesysRegion.CaCentral_1]: 'https://api.cac1.pure.cloud',
    [GenesysRegion.EuCentral_1]: 'https://api.mypurecloud.de',
    [GenesysRegion.EuWest_1]: 'https://api.mypurecloud.ie',
    [GenesysRegion.EuWest_2]: 'https://api.euw2.pure.cloud',
    [GenesysRegion.UsEast_1]: 'https://api.mypurecloud.com',
    [GenesysRegion.UsEast_2]: 'https://api.use2.us-gov-pure.cloud',
    [GenesysRegion.UsWest_2]: 'https://api.usw2.pure.cloud',
    [GenesysRegion.Wiremock]:
        'https://incentives-were-prizes-tabs.trycloudflare.com'
}

export const genesysRegions: GenesysRegion[] = [
    GenesysRegion.UsEast_1,
    GenesysRegion.UsEast_2,
    GenesysRegion.UsWest_2,
    GenesysRegion.CaCentral_1,
    GenesysRegion.EuWest_1,
    GenesysRegion.EuWest_2,
    GenesysRegion.EuCentral_1,
    GenesysRegion.ApNortheast_1,
    GenesysRegion.ApNortheast_2,
    GenesysRegion.ApSoutheast_2,
    GenesysRegion.ApSouth_1,
    GenesysRegion.Wiremock
]
