import React, { type ReactNode } from 'react'
import { AlertTitle, Snackbar } from '@mui/material'
import {
    CoreAlert,
    CoreTypography,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'

export interface ConfirmationSnackBarProps {
    message: ReactNode
    isOpen: boolean
    handleClose: () => void
}

export const ConfirmationSnackBar: React.FC<ConfirmationSnackBarProps> = ({
    message,
    isOpen,
    handleClose
}) => {
    return (
        <>
            <Snackbar
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <CoreAlert
                    onClose={handleClose}
                    severity="success"
                    icon={<LeafIcon icon={'check'} />}
                >
                    <AlertTitle>
                        <CoreTypography variant="body2">
                            {message}
                        </CoreTypography>
                    </AlertTitle>
                </CoreAlert>
            </Snackbar>
        </>
    )
}
