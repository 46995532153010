import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from '@mui/material'
import React, { ReactNode } from 'react'
import { validateUuid } from '../../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { useFormContext, Controller } from 'react-hook-form'
import {
    genesysApiHostByRegion,
    GenesysRegion,
    genesysRegions
} from '../../../pages/Company/IdentitySettings/SignupMethodsAndEmailDomains/SignupMethods/GenesysModals/constants'

const messages = defineMessages({
    regionInputLabel: {
        defaultMessage: 'Region*',
        description: 'Label for genesys region input'
    },
    regionRequiredLabel: {
        defaultMessage: 'Region is required.',
        description: 'Label for region required error'
    },
    companyIdInputLabel: {
        defaultMessage: 'Genesys Company Id',
        description: 'Label for genesys company ID input'
    },
    companyIdRequiredLabel: {
        defaultMessage: 'Genesys Company Id is required.',
        description: 'Label for genesys company ID required error'
    },
    companyIdInvalidLabel: {
        defaultMessage: 'Invalid Genesys Company Id.',
        description: 'Label for genesys company ID required error'
    }
} as const)

export interface GenesysConfigurationFormInputs {
    region: GenesysRegion | string
    genesysCompanyId: string
}

export const GenesysConfigurationInput: React.FC = () => {
    const intl = useIntl()
    const {
        control,
        formState: { errors }
    } = useFormContext()

    return (
        <Stack spacing={2}>
            <Controller
                render={({ field }) => (
                    <FormControl
                        fullWidth
                        variant="outlined"
                        error={!!errors?.region}
                    >
                        <InputLabel id="region">
                            {intl.formatMessage(messages.regionInputLabel)}
                        </InputLabel>
                        <Select
                            {...field}
                            required
                            fullWidth
                            value={field.value}
                            inputRef={field.ref}
                            onChange={field.onChange}
                            error={!!errors?.region}
                            label={intl.formatMessage(
                                messages.regionInputLabel
                            )}
                            labelId="region"
                        >
                            <MenuItem
                                key={0}
                                data-testid="empty-region"
                                value={''}
                            ></MenuItem>
                            {genesysRegions.map((region) => (
                                <MenuItem key={region} value={region}>
                                    {region} ({genesysApiHostByRegion[region]})
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText variant="standard">
                            <>{errors?.region?.message}</>
                        </FormHelperText>
                    </FormControl>
                )}
                control={control}
                name="region"
                rules={{
                    required: intl.formatMessage(messages.regionRequiredLabel),
                    validate: {
                        hasValue: (value) => {
                            return value
                                ? null
                                : intl.formatMessage(
                                      messages.regionRequiredLabel
                                  )
                        }
                    }
                }}
            />
            <Controller
                render={({ field }) => (
                    <TextField
                        required
                        fullWidth
                        spellCheck="false"
                        id="idp-company-id"
                        variant="outlined"
                        inputRef={field.ref}
                        value={field.value}
                        error={!!errors?.genesysCompanyId}
                        onChange={field.onChange}
                        label={intl.formatMessage(messages.companyIdInputLabel)}
                        helperText={<>{errors?.genesysCompanyId?.message}</>}
                        FormHelperTextProps={{
                            variant: 'standard'
                        }}
                    />
                )}
                control={control}
                rules={{
                    required: intl.formatMessage(
                        messages.companyIdRequiredLabel
                    ),
                    validate: {
                        validUuid: (value) => {
                            return validateUuid(value)
                                ? null
                                : intl.formatMessage(
                                      messages.companyIdInvalidLabel
                                  )
                        }
                    }
                }}
                name="genesysCompanyId"
            />
        </Stack>
    )
}

export default React.memo(GenesysConfigurationInput)
